<template>
        <div>
            <h3>Рекоммендации</h3>
            <tabletpl 
                v-bind:columns="columns"  
                v-bind:table_data="tableData" 
                v-bind:table_load="tableLoad"  
                v-on:add="addRow" 
                v-on:edit="editRow"
                >
            </tabletpl>

            <modal_edit 
                v-if='modal'
                v-bind:title="modalTitle"
                v-bind:view="modalView"
                v-on:close="modalClose"
                v-on:delete="deleteRow"
                ></modal_edit>
            <modal_delete 
                v-if='modal_delete'
                v-bind:view="modalView"
                v-on:close="modalDelClose"
                ></modal_delete>
        </div>
</template>

<script>
import tabletpl from '../../components/table.vue'
import modal_edit from './recommendation_edit.vue'
import modal_delete from './recommendation_delete.vue'

export default {
    data(){
        return{
            columns: [{"name": "name", "title": "Название"}],
            tableLoad: false,
            tableData: ["load"],
            modal:'',
            modal_delete:'',
            modalTitle:'',
            modalView:''
        }
    },
    components: {
        tabletpl,
        modal_edit,
        modal_delete
    },
    mounted:function(){
        this.dataLoad()
    },
    methods: {
        modalClose: function(prm){
            this.modal='';
            this.modal_delete='';
            if (prm=='reload'){
                this.dataLoad();
            }
        },
        modalDelClose: function(prm){
            
            this.modal_delete='';
            if (prm=='reload'){
                this.modal='';
                this.dataLoad();
            }
        },
        dataLoad: function(){
            var t=this;
            this.$http.post(this.$store.state.apiUrl+'recommendation')
            .then(
                (response) => {
                    if(response.body.err>0){
                        t.tableData=["error"];
                    }else{
                        t.tableData=response.body;
                    }
                }, 
                (err) => { 
                    console.log(err) 
                })
        },
        addRow: function() {
            this.modal=true;
            this.modalTitle = 'Добавление рекоммендации';
            this.modalView=[];
        },
        editRow: function(view) {
            this.modal=true;
            this.modalTitle = 'Редактирование рекоммендации';
            this.modalView=view;
        },
        deleteRow: function(view) {
            this.modal_delete=true;
            this.modalView=view;
        }
    }
}
</script>

<style>

</style>
